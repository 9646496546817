import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar, Footer } from './components';
import DataServices from '../../service/data-services';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 56
    }
  },
  shiftContent: {
    paddingLeft: 256
  },
  shiftContent2: {
    paddingLeft: 56
  },
  content: {
    flexGrow: 1,
    height: '100%',
    padding: theme.spacing(3)
  }
}));

const Main = props => {
  const { children } = props;
  const dataService =new DataServices( );

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState( (isDesktop? (dataService.isDrawerOpen()==='true'? true : false) : false) );

  const handleSidebarOpen = () => {
    setOpenSidebar(!openSidebar);
    dataService.setDrawerOpen(!openSidebar? "true" : "false");
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
    dataService.setDrawerOpen("false");
  };

  //const shouldOpenSidebar = isDesktop ? true : openSidebar;
    React.useEffect(() => {
      setOpenSidebar( (isDesktop? (dataService.isDrawerOpen()==='true'? true : false) : false) );
    }, [isDesktop])

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: (openSidebar && isDesktop),
        [classes.shiftContent2]: (!openSidebar && isDesktop)
      })}
    >
      <Sidebar
        onClose={handleSidebarClose}
        open={openSidebar}
        variant={isDesktop ? 'permanent' : 'temporary'}
        onSidebarOpen={handleSidebarOpen}
      />
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <main className={classes.content}>
        {children}
            <Footer />
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
