/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from  '@material-ui/core/styles';
import { List, ListItem, Button, colors, Collapse, Link, Badge, IconButton, Tooltip, Avatar } from '@material-ui/core';
import { Home as HomeIcon,
ExpandLess,
ExpandMore,
PowerSettingsNew as LogoutIcon,
Drafts as LeaveIcon, AlarmOn as AttendanceIcon,
ArrowRight as MenuClose,
ArrowDropDown as MenuOpen,
AttachMoney as AttachMoneyIcon,
LocalShipping as LocalShippingIcon,
AccountCircle as ProfileIcon,
Assignment as ReportIcon,
Tune as SettingsIcon,
Dashboard, Redeem, GetApp,
DoneAll,BorderAll, PeopleAlt,VerifiedUser,
ContactPhone, AddShoppingCart, AccountTree, Storefront as StorefrontIcon,
BusinessCenter as BusinessCenterIcon,
EventNote as EventNoteIcon, AccountBalance as AccountBalanceIcon,
ListAlt as ListAltIcon,ShoppingCart as ShoppingCartIcon,
Assignment as AssignmentIcon, ShoppingBasket as ShoppingBasketIcon, CastConnected as CastConnectedIcon,
Timeline as TimelineIcon, BubbleChart as BubbleChartIcon,KeyboardArrowRight as KeyboardArrowRightIcon,
Policy as PolicyIcon, CancelPresentation as CancelPresentationIcon, Facebook as FacebookIcon,
SupervisedUserCircle as SupervisedUserCircleIcon, LiveHelp as LiveHelpIcon,
Receipt as ReceiptIcon, Storage as StorageIcon, VideoCall as VideoCallIcon, InsertLink as InsertLinkIcon} from '@material-ui/icons';
import DataServices from '../../../../../../service/data-services';
import Configuration from '../../../../../../service/configuration';
import Resources from '../../../../../../service/resources';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import moment from 'moment';
import { useTranslation, withTranslation } from 'react-i18next'
import { Inventory as InventoryIcon, PointOfSale as PointOfSaleIcon, ProductionQuantityLimits as ProductionQuantityLimitsIcon } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  root: {margin:0,padding:0},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },  
  itemNested: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(4)
  },
  button: {
    color: theme.palette.white,
    padding: '5px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
          backgroundColor: colors.blueGrey[600],
          color: theme.palette.white,
        }
  },
  buttonHide: {
    color: theme.palette.white,
    padding: '5px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
          backgroundColor: colors.blueGrey[600],
          color: theme.palette.white,
        }
  },
  icon: {
    color: theme.palette.white,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  drawerIcon: {
    color: theme.palette.white,
  },
  active: {
    backgroundColor: theme.palette.white,
    color: colors.blueGrey[800],
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: colors.orange[600]
    }
  },
 itemNested2: {
   display: 'flex',
   paddingTop: 0,
   paddingBottom: 0,
   paddingLeft: theme.spacing(6)
 },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
  
    <RouterLink {...props} />
        
  </div>
));

const SidebarNav = props => {
  const { pages, className, open, onSidebarOpen, ...rest } = props;

    const dataService = new DataServices( );
    const config = new Configuration();
    const resources = new Resources( );
    var auth = dataService.getAuthoritiesData( );
    var userData = dataService.getCurrentUserData();
    var posPrivilege = dataService.getCurrentUserPOSPrivilege();


    const classes = useStyles();

    var t = props.lang;
    var i18n = props.i18n;

    const [openDashboard, setOpenDashboard] = React.useState(window.location.pathname.includes('organization_admin_dashboard'));

    const [openDelivery, setOpenDelivery] = React.useState(window.location.pathname.includes('delivery'));
    const handleClickDelivery = () => {
      setOpenDelivery(!openDelivery);
    };
    const [openUsers, setOpenUsers] = React.useState(window.location.pathname.includes('user_'));
    const handleClickUsers = () => {
        setOpenUsers(!openUsers);
    };
    const [openSettings, setOpenSettings] = React.useState(window.location.pathname.includes('settings') || window.location.pathname.includes('user_'));
    const handleClickSettings = () => {
        setOpenSettings(!openSettings);
    };
    const [openPos, setOpenPos] = React.useState(window.location.pathname.includes('pos_'));
    const handleClickPos = () => {
      setOpenPos(!openPos);
    };
    const [openAccounting, setOpenAccounting] = React.useState(window.location.pathname.includes('accounting_'));
    const handleClickAccounting = () => {
      setOpenAccounting(!openAccounting);
    };
    const [openAccounts, setOpenAccounts] = React.useState(window.location.pathname.includes('accounts_'));
    const handleClickAccounts = () => {
      setOpenAccounts(!openAccounts);
    };
    const [openTransaction, setOpenTransaction] = React.useState(window.location.pathname.includes('transaction_'));
    const handleClickTransaction = () => {
      setOpenTransaction(!openTransaction);
    };
    const [openFinancial, setOpenFinancial] = React.useState(window.location.pathname.includes('financial_'));
    const handleClickFinancial = () => {
      setOpenFinancial(!openFinancial);
    };

    const [openPosOrder, setOpenPosOrder] = React.useState(window.location.pathname.includes('sale'));
    const handleClickPosSale = () => {
      setOpenPosOrder(!openPosOrder);
    };
    const [openBooster, setOpenBooster] = React.useState(window.location.pathname.includes('booster'));
    const handleClickBooster = () => {
      setOpenBooster(!openBooster);
    };

    const hrmLogin = () =>{
        dataService.retrieve( config.SERVICE_NAME_USER, config.SERVICE_ACTION_USER_HR_AUTHORIZATION).then(items => {
            if( items && items.success ) {
                var anchor = document.createElement('a');
//                anchor.href = 'https://hrdemo.gigagates.com/login/' + items.data.access_token +'/'+ items.data.expires_in +'/'+ items.data.refresh_token;
                anchor.href = 'https://ghr.gigagates.com/login/' + items.data.access_token +'/'+ items.data.expires_in +'/'+ items.data.refresh_token;
//                anchor.href = 'https://hrtest.quickdoor2door.com/login/' + items.data.access_token +'/'+ items.data.expires_in +'/'+ items.data.refresh_token;
                anchor.target="_blank";
                anchor.click();
            }else{
                alert(items.message);
            }
        });
    }

    const getFacebookPageList = (type, condition ) =>{
        dataService.retrieve(
        config.SERVICE_NAME_FACEBOOK_CONNECT, '?status=Connected').then(items => {

            if(items) {
                if( items.success && items.data.items && items.data.items.length >0){
                    var contentItem =items.data.items[0];
                    var anchor = document.createElement('a');
                    if( type==='message' && condition==='connect')
                        anchor.href ='/booster_campaign_create/' + contentItem.pageId + '/' + encodeURIComponent(contentItem.name);
                    else
                        anchor.href ='/'+ (type==='live'? 'booster_fb_live_video_sales' : (type==='post'? 'booster_fb_live_post_sales' : 'booster_campaign_list') ) +'/facebook/' + contentItem.pageId + '/' + encodeURIComponent(contentItem.name) + '/' + encodeURIComponent(contentItem.coverPhoto? contentItem.coverPhoto : '') +'/' +condition;

                    anchor.click();
                }else{
                    alert(items.message);
                }
            }else{
                alert(this.config.MESSAGE_REQUEST_TIMEOUT);
            }
        });
    }

    const [openProduct, setOpenProduct] = React.useState(window.location.pathname.includes('products'));
    const handleClickProduct = () => {
        setOpenProduct(!openProduct);
    };

    const [openInventory, setOpenInventory] = React.useState(window.location.pathname.includes('inventories'));
    const handleClickInventory = () => {
        setOpenInventory(!openInventory);
    };

    const [openBroadcast, setOpenBroadcast] = React.useState(window.location.pathname.includes('broadcast_'));
    const handleClickBroadcast = () => {
      setOpenBroadcast(!openBroadcast);
    };

    const [openSubscriber, setOpenSubscriber] = React.useState(window.location.pathname.includes('subscriber_'));
    const handleClickSubscriber = () => {
      setOpenSubscriber(!openSubscriber);
    };

    const [openPackage, setOpenPackage] = React.useState(window.location.pathname.includes('package_'));
    const handleClickPackage = () => {
      setOpenPackage(!openPackage);
    };

    const [openSupportTicket, setOpenSupportTicket] = React.useState(window.location.pathname.includes('support_ticket_'));
    const handleClickSupportTicket = () => {
      setOpenSupportTicket(!openSupportTicket);
    };

    const [openBilling, setOpenBilling] = React.useState(window.location.pathname.includes('billing_'));
    const handleClickBilling = () => {
      setOpenBilling(!openBilling);
    };
    const [openSuperUsers, setOpenSuperUsers] = React.useState(window.location.pathname.includes('superuser_'));
    const handleClickSuperUsers = () => {
        setOpenSuperUsers(!openSuperUsers);
    };
    const [openMessaging, setOpenMessaging] = React.useState(window.location.pathname.includes('booster_campaign_'));
    const handleClickMessaging = () => {
      setOpenMessaging(!openMessaging);
    };
    const [openLiveVideo, setOpenLiveVideo] = React.useState(window.location.pathname.includes('_live_video_'));
    const handleClickLiveVideo = () => {
      setOpenLiveVideo(!openLiveVideo);
    };
    const [openLivePost, setOpenLivePost] = React.useState(window.location.pathname.includes('_live_post_'));
    const handleClickLivePost = () => {
      setOpenLivePost(!openLivePost);
    };

  return (
          <div>

        {/* Subscriber menu full*/}
        { (open && auth.includes('PACKAGE_SUBSCRIBER_LIST') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickSubscriber();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<SupervisedUserCircleIcon />}</div>
                    {'Subscribers'}
                  </Button>
                {openSubscriber? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openSubscriber} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('PACKAGE_SUBSCRIBER_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/subscriber_add'} >
                            {'Add new subscriber'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_SUBSCRIBER_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/subscriber_list'} >
                            {'Subscriber list'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Subscriber menu mini*/}
        { (!open && auth.includes('PACKAGE_SUBSCRIBER_LIST') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Subscribers'} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenSubscriber(true);
                      setOpenPackage(false);
                      setOpenSupportTicket(false);
                      setOpenBilling(false);
                      setOpenSuperUsers(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<SupervisedUserCircleIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Package menu full*/}
        { (open && auth.includes('PACKAGE_LIST') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickPackage();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<BusinessCenterIcon />}</div>
                    {'Packages'}
                  </Button>
                {openPackage? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openPackage} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {/*{ auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/package_create'} >
                            {'Add new package'}
                          </Button>
                        </ListItem>
                    }*/}
                    { auth.includes('PACKAGE_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/package_list'} >
                            {'Package list'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/package_subscribe_package'} >
                            {'Subscribe a package'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/package_purchasing_packages'} >
                            {'Package subscription list'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Package menu mini*/}
        { (!open && auth.includes('PACKAGE_LIST') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Packages'} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenSubscriber(false);
                      setOpenPackage(true);
                      setOpenSupportTicket(false);
                      setOpenBilling(false);
                      setOpenSuperUsers(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<BusinessCenterIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Billing menu full*/}
        { (open && auth.includes('PACKAGE_PURCHASE') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickBilling();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<ReceiptIcon />}</div>
                    {'Billing'}
                  </Button>
                {openBilling? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openBilling} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {/*{ auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/billing_new_invoices'} >
                            {'New invoice'}
                          </Button>
                        </ListItem>
                    }*/}
                    { auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/billing_invoice_list/Pending'} >
                            {'Pending invoices'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/billing_invoice_list/Paid'} >
                            {'Paid invoices'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/billing_invoice_list/Overdue'} >
                            {'Overdue invoices'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/billing_invoice_list/Rejected'} >
                            {'Rejected invoices'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Billing menu mini*/}
        { (!open && auth.includes('PACKAGE_PURCHASE') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Billing'} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenSubscriber(false);
                      setOpenPackage(false);
                      setOpenSupportTicket(false);
                      setOpenBilling(true);
                      setOpenSuperUsers(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<ReceiptIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Support ticket menu full*/}
        {/*{ (open && auth.includes('PACKAGE_LIST') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickSupportTicket();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<LiveHelpIcon />}</div>
                    {'Customer Support'}
                  </Button>
                {openSupportTicket? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openSupportTicket} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('PACKAGE_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_page_posts/post'} >
                            {'New ticket'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_page_lives/live'} >
                            {'Ticket list'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_page_lives/live'} >
                            {'Ticket category'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_page_lives/live'} >
                            {'Ticket assign'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }*/}
        {/* Support ticket menu mini*/}
        {/*{ (!open && auth.includes('PACKAGE_LIST') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Customer Support'} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenSubscriber(false);
                      setOpenPackage(false);
                      setOpenSupportTicket(true);
                      setOpenBilling(false);
                      setOpenSuperUsers(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<LiveHelpIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }*/}

        {/* Super user menu full*/}
        { ( open && auth.includes('PACKAGE_SUPER_USER_LIST') && userData && userData.roleName.includes('Super') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickSuperUsers();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<PeopleAlt />}</div>
                    {'Super user accounts'}
                  </Button>
                {openSuperUsers? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openSuperUsers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/superuser_list/Admin'} >
                          {'Admin'}
                        </Button>
                    </ListItem>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/superuser_list/Support'} >
                        {'Supporting'}
                      </Button>
                    </ListItem>
                    <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/superuser_list/Sales'} >
                          {'Sales'}
                        </Button>
                    </ListItem>
                </List>
            </Collapse>
        </List>
        }
        {/* Super user menu mini*/}
        { (!open && auth.includes('USER_ACCOUNT_CREATE') && userData && userData.roleName.includes('Super') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.people')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenSubscriber(false);
                      setOpenPackage(false);
                      setOpenSupportTicket(false);
                      setOpenBilling(false);
                      setOpenSuperUsers(true);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<PeopleAlt />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Admin menu full*/}
        { ( open && auth.includes('MANAGER_READ') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={'/organization_admin_dashboard'}
                  >
                <div className={classes.icon}>{<Dashboard />}</div>
                  {t('menu.dashboard')}
                </Button>
              </ListItem>
          </List>
        }
        {/* Admin menu mini*/}
        { ( !open && auth.includes('MANAGER_READ') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.dashboard')} arrow>
                  <Button
                      className={classes.buttonHide}
                      component={CustomRouterLink}
                      to={'/organization_admin_dashboard'}
                  >
                    <div className={classes.icon}>{<Dashboard />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Sales menu full*/}
        { ( open && userData && userData.roleName.includes('_Sales') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={'/sales_dashboard'}
                  >
                <div className={classes.icon}>{<Dashboard />}</div>
                  {t('menu.dashboard')}
                </Button>
              </ListItem>
          </List>
        }
        {/* Sales menu mini*/}
        { ( !open && userData && userData.roleName.includes('Sales') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      className={classes.buttonHide}
                      component={CustomRouterLink}
                      to={'/sales_dashboard'}
                  >
                  <div className={classes.icon}>{<Dashboard />}</div>
                  {''}
                </Button>
              </ListItem>
          </List>
        }

        {/* Finance menu full*/}
        { ( open && userData && userData.roleName.includes('Finance') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={'/finance_dashboard'}
                  >
                <div className={classes.icon}>{<Dashboard />}</div>
                  {t('menu.dashboard')}
                </Button>
              </ListItem>
          </List>
        }
        {/* Finance menu mini*/}
        { ( !open && userData && userData.roleName.includes('Finance') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      className={classes.buttonHide}
                      component={CustomRouterLink}
                      to={'/finance_dashboard'}
                  >
                <div className={classes.icon}>{<Dashboard />}</div>
                  {''}
                </Button>
              </ListItem>
          </List>
        }

        {/* Sales order list menu full*/}
        { ( open && auth.includes('MANAGER_READ') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={'/pos_sales_order_list'}
                  >
                <div className={classes.icon}>{<ShoppingCartIcon />}</div>
                  {t('menu.pos.sales.order.list')}
                </Button>
              </ListItem>
          </List>
        }
        {/* Sales order list menu mini*/}
        { ( !open && auth.includes('MANAGER_READ') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.pos.sales.order.list')} arrow>
                  <Button
                      className={classes.buttonHide}
                      component={CustomRouterLink}
                      to={'/pos_sales_order_list'}
                  >
                    <div className={classes.icon}>{<ShoppingCartIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Product menu full*/}
        { (open && auth.includes('INVENTORY_PURCHASE_CREATE')) &&
        <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickProduct();}}>
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<StorageIcon />}</div>
                  {t('menu.pos.product')}
                </Button>
                {openProduct? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
              </ListItem>
              <Collapse in={openProduct} timeout="auto" unmountOnExit>

                    <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/pos_products_normal_item_list'} >
                          {t('menu.pos.product.item')}
                        </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/pos_products_normal_item_import'} >
                          {'Import product'}
                        </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/pos_products_category_list'} >
                          {t('menu.pos.product.category')}
                        </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/pos_products_addons_list'} >
                          {t('menu.pos.product.addon')}
                        </Button>
                    </ListItem>

              </Collapse>
        </List>
        }
        {/* Product menu mini*/}
        { (!open && auth.includes('INVENTORY_PURCHASE_CREATE')) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.pos.product')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenProduct(true);
                      setOpenInventory(false);
                      setOpenPos(false);
                      setOpenDelivery(false);
                      setOpenAccounting(false);
                      setOpenBooster(false);
                      setOpenUsers(false);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<StorageIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Purchase menu full*/}
        { (open && auth.includes('INVENTORY_PURCHASE_CREATE')) &&
        <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickInventory();}}>
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<ShoppingBasketIcon />}</div>
                  {t('menu.pos.purchase')}
                </Button>
                {openInventory? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
              </ListItem>
              <Collapse in={openInventory} timeout="auto" unmountOnExit>

                  <ListItem className={classes.itemNested} disableGutters >
                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                    to={'/pos_inventories_purchase_order_list'} >
                      {t('menu.pos.purchase.order')}
                    </Button>
                  </ListItem>

                  <ListItem className={classes.itemNested} disableGutters >
                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                    to={'/pos_inventories_purchase_item_list'} >
                      {t('menu.pos.purchase.by.item')}
                    </Button>
                  </ListItem>

                  <ListItem className={classes.itemNested} disableGutters >
                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                    to={'/pos_inventories_stock_quantity_logs'} >
                      {t('menu.pos.purchase.quantity.logs')}
                    </Button>
                  </ListItem>

                  <ListItem className={classes.itemNested} disableGutters >
                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                    to={'/pos_inventories_stock_price_logs'} >
                      {t('menu.pos.purchase.price.logs')}
                    </Button>
                  </ListItem>

                  <ListItem className={classes.itemNested} disableGutters >
                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                    to={'/pos_inventories_damage_list'} >
                      {t('menu.pos.purchase.damage')}
                    </Button>
                  </ListItem>

                  {auth.includes('FINANCE_READ') &&
                  <ListItem className={classes.itemNested} disableGutters >
                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                    to={'/pos_inventories_supplier_payment_list'} >
                      {t('menu.pos.purchase.supplier.payment')}
                    </Button>
                  </ListItem>
                  }

              </Collapse>
        </List>
        }
        {/* Purchase menu mini*/}
        { (!open && auth.includes('INVENTORY_PURCHASE_CREATE')) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.pos.purchase')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenInventory(true);
                      setOpenProduct(false);
                      setOpenPos(false);
                      setOpenDelivery(false);
                      setOpenAccounting(false);
                      setOpenBooster(false);
                      setOpenUsers(false);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<ShoppingBasketIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }


        {/* Live sales menu full*/}
        { (open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickLiveVideo();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<VideoCallIcon />}</div>
                    {t('menu.booster.liveSales')}
                  </Button>
                {openLiveVideo? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openLiveVideo} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button}
                          onClick={event =>{getFacebookPageList('live', 'connect');}} >
                            {t('form.facebook.liveVideoList.button.connection')}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button}
                          onClick={event =>{getFacebookPageList('live', 'history');}} >
                            {'History'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Live sales menu mini*/}
        { (!open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.booster.liveSales')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenLiveVideo(true);
                      setOpenPos(false);
                      setOpenDelivery(false);
                      setOpenAccounting(false);
                      setOpenBooster(false);
                      setOpenUsers(false);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<VideoCallIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Post sales menu full*/}
        { (open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickLivePost();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<InsertLinkIcon />}</div>
                    {t('menu.booster.postSales')}
                  </Button>
                {openLivePost? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openLivePost} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button}
                          onClick={event =>{getFacebookPageList('post', 'connect');}} >
                            {t('form.facebook.postSaleList.button.connection')}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button}
                          onClick={event =>{getFacebookPageList('post', 'history');}} >
                            {'History'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Post sales menu mini*/}
        { (!open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.booster.postSales')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenLivePost(true);
                      setOpenPos(false);
                      setOpenDelivery(false);
                      setOpenAccounting(false);
                      setOpenBooster(false);
                      setOpenUsers(false);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<InsertLinkIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Message broadcasting menu full*/}
        { (open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickMessaging();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<Avatar style={{width:24, height:24}} alt={'Messenger'} src={'/messenger.png'} />}</div>
                    {t('menu.booster.freeBoost')}
                  </Button>
                {openMessaging? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openMessaging} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button}
                          onClick={event =>{getFacebookPageList('message', 'connect');}} >
                            {'Create Campaign'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button}
                          onClick={event =>{getFacebookPageList('message', 'history');}} >
                            {'History'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Message broadcasting menu mini*/}
        { (!open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.booster.freeBoost')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenMessaging(true);
                      setOpenPos(false);
                      setOpenDelivery(false);
                      setOpenAccounting(false);
                      setOpenBooster(false);
                      setOpenUsers(false);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<Avatar style={{width:24, height:24}} alt={'Messenger'} src={'/messenger.png'} />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }


        {/* POS menu full*/}
        {/*{ (open && auth.includes('SALES_ORDER_LIST')) &&
        <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickPos();}}>
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<ShoppingCartIcon />}</div>
                  {t('menu.pos.pos')}
                </Button>
                {openPos? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
              </ListItem>
              <Collapse in={openPos} timeout="auto" unmountOnExit>
                    { auth.includes('INVENTORY_PURCHASE_CREATE') &&
                    <List {...rest} className={clsx(classes.root, className)}>
                          <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickProduct();}}>
                            {openProduct ? <MenuOpen className={classes.drawerIcon} /> : <MenuClose className={classes.drawerIcon} />}
                            <Button activeClassName={classes.active} className={classes.button} >
                              {t('menu.pos.product')}
                            </Button>
                          </ListItem>

                          <Collapse in={openProduct} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_products_category_list'} >
                                      {t('menu.pos.product.category')}
                                    </Button>
                                  </ListItem>

                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_products_normal_item_list'} >
                                      {t('menu.pos.product.item')}
                                    </Button>
                                  </ListItem>

                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_products_addons_list'} >
                                      {t('menu.pos.product.addon')}
                                    </Button>
                                  </ListItem>

                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_products_normal_item_import'} >
                                      {'Import product'}
                                    </Button>
                                  </ListItem>

                              </List>
                          </Collapse>
                    </List>
                    }

                    { auth.includes('INVENTORY_PURCHASE_CREATE') &&
                    <List {...rest} className={clsx(classes.root, className)}>
                          <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickInventory();}}>
                            {openInventory ? <MenuOpen className={classes.drawerIcon} /> : <MenuClose className={classes.drawerIcon} />}
                            <Button activeClassName={classes.active} className={classes.button} >
                              {t('menu.pos.purchase')}
                            </Button>
                          </ListItem>

                          <Collapse in={openInventory} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>


                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_inventories_purchase_order_list'} >
                                      {t('menu.pos.purchase.order')}
                                    </Button>
                                  </ListItem>

                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_inventories_purchase_item_list'} >
                                      {t('menu.pos.purchase.by.item')}
                                    </Button>
                                  </ListItem>

                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_inventories_stock_quantity_logs'} >
                                      {t('menu.pos.purchase.quantity.logs')}
                                    </Button>
                                  </ListItem>

                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_inventories_stock_price_logs'} >
                                      {t('menu.pos.purchase.price.logs')}
                                    </Button>
                                  </ListItem>

                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_inventories_damage_list'} >
                                      {t('menu.pos.purchase.damage')}
                                    </Button>
                                  </ListItem>
                                  {auth.includes('FINANCE_READ') &&
                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_inventories_supplier_payment_list'} >
                                      {t('menu.pos.purchase.supplier.payment')}
                                    </Button>
                                  </ListItem>
                                  }
                              </List>
                          </Collapse>
                    </List>
                    }

                    <List {...rest} className={clsx(classes.root, className)}>
                          <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickPosSale();}}>
                            {openPosOrder ? <MenuOpen className={classes.drawerIcon} /> : <MenuClose className={classes.drawerIcon} />}
                            <Button activeClassName={classes.active} className={classes.button} >
                              {t('menu.pos.sales')}
                            </Button>
                          </ListItem>

                          <Collapse in={openPosOrder} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_sales_sale_order'} >
                                      {t('menu.pos.sales.order')}
                                    </Button>
                                  </ListItem>
                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_sales_order_list'} >
                                      {t('menu.pos.sales.order.list')}
                                    </Button>
                                  </ListItem>
                                  {auth.includes('FINANCE_READ') &&
                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_sales_order_payment_list'} >
                                      {t('menu.pos.sales.customer.payment')}
                                    </Button>
                                  </ListItem>
                                  }
                                  <ListItem className={classes.itemNested} disableGutters >
                                    <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                    to={'/pos_sales_order_items'} >
                                      {t('menu.pos.sales.order.by.item')}
                                    </Button>
                                  </ListItem>
                              </List>
                          </Collapse>
                    </List>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/pos_shops_list'} >
                        {t('menu.pos.store.list')}
                      </Button>
                    </ListItem>

              </Collapse>
        </List>
        }*/}
        {/* POS menu mini*/}
        {/*{ (!open && auth.includes('SALES_ORDER_LIST')) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.pos.pos')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenPos(true);
                      setOpenDelivery(false);
                      setOpenAccounting(false);
                      setOpenBooster(false);
                      setOpenUsers(false);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<ShoppingCartIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }*/}

        {/* Delivery menu full*/}
        { (open && auth.includes('SALES_ORDER_LIST')) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickDelivery();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<LocalShippingIcon />}</div>
                    {t('menu.delivery')}
                  </Button>
                {openDelivery? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openDelivery} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/delivery_order_list'} >
                        {t('menu.delivery.parcels')}
                      </Button>
                    </ListItem>

                    { auth.includes('DELIVERY_COMPANY_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/delivery_shipping_services'} >
                            {t('menu.delivery.services')}
                          </Button>
                        </ListItem>
                    }

                    { auth.includes('ACCOUNTING_DAILY_ACCOUNTING_READ') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/delivery_shipping_payment_list'} >
                            {t('menu.delivery.payment')}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Delivery menu mini*/}
        { (!open && auth.includes('SALES_ORDER_LIST')) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.delivery')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenPos(false);
                      setOpenDelivery(true);
                      setOpenAccounting(false);
                      setOpenBooster(false);
                      setOpenUsers(false);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<LocalShippingIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Accounting menu full*/}
        { ( open && auth.includes('FINANCE_READ') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickAccounting();}}>
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<AccountBalanceIcon />}</div>
                  {t('menu.accounting')}
                </Button>
                {openAccounting? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
              </ListItem>

              <Collapse in={openAccounting} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>

                    <List {...rest} className={clsx(classes.root, className)}>
                      <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickAccounts();}}>
                        {openAccounts ? <MenuOpen className={classes.drawerIcon} /> : <MenuClose className={classes.drawerIcon} />}
                        <Button activeClassName={classes.active} className={classes.button} >
                          {t('menu.accounts')}
                        </Button>
                      </ListItem>
                      <Collapse in={openAccounts} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          { auth.includes('ACCOUNTING_ACCOUNT_BALANCE_READ') &&
                              <ListItem className={classes.itemNested2} disableGutters >
                                <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                to={'/accounting_accounts_balance'} >
                                  {t('menu.accounts_balance')}
                                </Button>
                              </ListItem>
                          }
                          { auth.includes('ACCOUNTING_ACCOUNT_LIST') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_accounts_title_list'} >
                              {t('menu.account_name_title')}
                            </Button>
                          </ListItem>
                          }
                        </List>
                      </Collapse>
                    </List>

                    <List {...rest} className={clsx(classes.root, className)}>
                      <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickTransaction();}}>
                        {openTransaction ? <MenuOpen className={classes.drawerIcon} /> : <MenuClose className={classes.drawerIcon} />}
                        <Button activeClassName={classes.active} className={classes.button} >
                          {t('menu.transactions')}
                        </Button>
                      </ListItem>
                      <Collapse in={openTransaction} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                          { auth.includes('ACCOUNTING_SIMPLE_TRANSACTION_CREATE') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_simple_journal_entry'} >
                              {t('menu.simple_transaction')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_VOUCHER_CREATE') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_account_level_journal_entry/Non-Cash'} >
                              {t('menu.journal_voucher_entry')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_VOUCHER_CREATE') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_account_level_cash_entry/Cash'} >
                              {'Cash voucher entry'}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_VOUCHER_LIST') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_journal_voucher_list/Non-Cash'} >
                              {t('menu.general_journal_list')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_VOUCHER_LIST') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_cash_voucher_list'} >
                              {'Cash voucher list'}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_LEDGER_LIST') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_general_ledger_list'} >
                              {t('menu.general_ledger_list')}
                            </Button>
                          </ListItem>
                          }

                        </List>
                      </Collapse>
                    </List>

                    <List {...rest} className={clsx(classes.root, className)}>
                      <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickFinancial();}}>
                        {openFinancial ? <MenuOpen className={classes.drawerIcon} /> : <MenuClose className={classes.drawerIcon} />}
                        <Button activeClassName={classes.active} className={classes.button} >
                          {t('menu.financial_reports')}
                        </Button>
                      </ListItem>
                      <Collapse in={openFinancial} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                          { auth.includes('ACCOUNTING_DAILY_ACCOUNTING_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_daily_accounting'} >
                              {t('menu.daily_accounting')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_SUMMARY_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_journal_summary'} >
                              {t('menu.journal_summary')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_TRIAL_BALANCE_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_trial_balance'} >
                              {t('menu.trial_balance')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_INCOME_STATEMENT_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_income_statement_list'} >
                              {t('menu.income_statement')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_BALANCE_SHEET_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_balance_sheet'} >
                              {t('menu.balance_sheet')}
                            </Button>
                          </ListItem>
                          }

                          { auth.includes('ACCOUNTING_PROFIT_LOSS_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_profit_and_loss'} >
                              {t('menu.profit_and_loss')}
                            </Button>
                          </ListItem>
                          }
                        </List>
                      </Collapse>
                    </List>
                  </List>
              </Collapse>
          </List>
        }
        {/* Accounting menu mini*/}
        { (!open && auth.includes('FINANCE_READ') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.accounting')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenPos(false);
                      setOpenDelivery(false);
                      setOpenAccounting(true);
                      setOpenBooster(false);
                      setOpenUsers(false);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<AccountBalanceIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Billings & Subscriptions menu full*/}
        { ( open && auth && auth.includes('ORGANIZATION_READ') ) &&
              <List {...rest} className={clsx(classes.root, className)}>
                <ListItem className={classes.item} disableGutters >
                    <Button
                        activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'/subscriptions_profile'}
                    >
                  <div className={classes.icon}>{<AttachMoneyIcon />}</div>
                    {'Billings & Subscriptions'}
                  </Button>
                </ListItem>
              </List>
        }
        {/* Billings & Subscriptions menu mini*/}
        { (!open && auth && auth.includes('ORGANIZATION_READ') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Billings & Subscriptions'} arrow>
                  <Button
                      className={classes.buttonHide}
                        component={CustomRouterLink}
                        to={'/subscriptions_profile'}
                  >
                    <div className={classes.icon}>{<AttachMoneyIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Settings menu full*/}
        { ( open && (auth.includes('SETTING_LOCAL_UPDATE') || auth.includes('SETTING_GLOBAL_UPDATE')) ) &&
            <List {...rest} className={clsx(classes.root, className)}>
                <ListItem className={classes.item} disableGutters onClick={event =>{handleClickSettings();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<SettingsIcon />}</div>
                    {t('menu.settings')}
                  </Button>
                  {(openSettings || openUsers)? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
                </ListItem>
                <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        { auth.includes('SETTING_GLOBAL_READ') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_systems'} >
                            {t('menu.system_settings')}
                          </Button>
                        </ListItem>
                        }

                        { ( open && auth.includes('USER_ACCOUNT_CREATE') && userData && !userData.roleName.includes('Super') ) &&
                        <List {...rest} className={clsx(classes.root, className)}>
                              <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickUsers();}}>
                                {openUsers ? <MenuOpen className={classes.drawerIcon} /> : <MenuClose className={classes.drawerIcon} />}
                                <Button activeClassName={classes.active} className={classes.button} >
                                  {t('menu.people')}
                                </Button>
                              </ListItem>

                              <Collapse in={openUsers} timeout="auto" unmountOnExit>
                                  <List component="div" disablePadding>
                                    <ListItem className={classes.itemNested} disableGutters >
                                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                        to={'/user_inventories_supplier_list'} >
                                          {t('menu.pos.purchase.supplier')}
                                        </Button>
                                    </ListItem>
                                    <ListItem className={classes.itemNested} disableGutters >
                                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                        to={'/user_sales_customer_list'} >
                                          {t('menu.pos.sales.customers')}
                                        </Button>
                                    </ListItem>

                                    { auth.includes('USER_ACCOUNT_LIST') &&
                                    <ListItem className={classes.itemNested} disableGutters >
                                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                      to={'/user_branch/branch'} >
                                        {t('menu.people.user')}
                                      </Button>
                                    </ListItem>
                                    }

                                  </List>
                              </Collapse>
                        </List>
                        }

                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_shops_list'} >
                            {t('menu.pos.store.list')}
                          </Button>
                        </ListItem>

                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_currency'} >
                            {t('menu.settings.currencies')}
                          </Button>
                        </ListItem>

                        { auth.includes('FACEBOOK_PAGE_LISTING') &&
                            <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/settings_image_list'} >
                                {t('menu.settings.mediaFiles')}
                              </Button>
                            </ListItem>
                        }

                    </List>
                </Collapse>
            </List>
        }
        {/* Settings menu mini*/}
        { (!open && (auth.includes('SETTING_LOCAL_UPDATE') || auth.includes('SETTING_GLOBAL_UPDATE')) ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.settings')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenPos(false);
                      setOpenDelivery(false);
                      setOpenAccounting(false);
                      setOpenBooster(false);
                      setOpenUsers(false);
                      setOpenSettings(true);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<SettingsIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Booster menu full*/}
        {/*{ (open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickBooster();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<FacebookIcon />}</div>
                    {t('menu.booster')}
                  </Button>
                {openBooster? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openBooster} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_page_lives/live'} >
                            {t('menu.booster.liveSales')}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_page_posts/post'} >
                            {t('menu.booster.postSales')}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_page_messages/messages'} >
                            {t('menu.booster.freeBoost')}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_page_audiences/audiences'} >
                            {t('menu.booster.audiences')}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_payment_screenshot_matching'} >
                            {t('menu.booster.screenshotVerification')}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }*/}
        {/* Booster menu mini*/}
        {/*{ (!open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.booster')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenPos(false);
                      setOpenDelivery(false);
                      setOpenAccounting(false);
                      setOpenBooster(true);
                      setOpenUsers(false);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<FacebookIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }*/}

        {/* People menu full*/}
        {/*{ ( open && auth.includes('USER_ACCOUNT_CREATE') && userData && !userData.roleName.includes('Super') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickUsers();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<PeopleAlt />}</div>
                    {t('menu.people')}
                  </Button>
                {openUsers? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openUsers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button}
                      onClick={event =>{hrmLogin();}}
                      >
                        {t('menu.hr_management')}
                      </Button>
                    </ListItem>

                </List>
            </Collapse>
        </List>
        }*/}
        {/* People menu mini*/}
        {/*{ (!open && auth.includes('USER_ACCOUNT_CREATE') && userData && !userData.roleName.includes('Super') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.people')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenPos(false);
                      setOpenDelivery(false);
                      setOpenAccounting(false);
                      setOpenBooster(false);
                      setOpenUsers(true);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<PeopleAlt />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }*/}


        {/* Logs menu full*/}
        {/*{ ( open && auth && auth.includes('LOGS_READ') ) &&
              <List {...rest} className={clsx(classes.root, className)}>
                  <ListItem className={classes.item} disableGutters >
                      <Button
                          activeClassName={classes.active}
                          className={classes.button}
                          component={CustomRouterLink}
                          to={'/audit_logs'}
                      >
                    <div className={classes.icon}>{<EventNoteIcon />}</div>
                      {t('menu.audit_logs')}
                    </Button>
                  </ListItem>
              </List>
        }*/}
        {/* Logs menu mini*/}
        {/*{ (!open && auth && auth.includes('LOGS_READ') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.audit_logs')} arrow>
                  <Button
                      className={classes.buttonHide}
                      component={CustomRouterLink}
                      to={'/audit_logs'}
                  >
                    <div className={classes.icon}>{<EventNoteIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }*/}

        {/* TOC menu full*/}
        { open &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters >
                <Link
                            component="a"
                            href="/toc.html"
                            target="_blank">
                <Button
                    activeClassName={classes.active}
                    className={classes.button} >
                  <div className={classes.icon}>{<PolicyIcon />}</div>
                    {t('menu.terms_conditions')}
                </Button>
                </Link>
            </ListItem>
        </List>
        }
        {/* TOC menu mini*/}
        { !open &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.terms_conditions')} arrow>
                <Link
                    component="a"
                    href="/toc.html"
                    target="_blank">
                  <Button
                      className={classes.buttonHide}
                  >
                    <div className={classes.icon}>{<PolicyIcon />}</div>
                    {''}
                  </Button>
                </Link>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Logout menu full*/}
        { open &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters >
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    onClick={event =>{dataService.removeTokenCookie();}}
                >
              <div className={classes.icon}>{<CancelPresentationIcon />}</div>
                {t('menu.logout')}
              </Button>
            </ListItem>
        </List>
        }
        {/* Logout menu mini*/}
        { !open &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.logout')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{dataService.removeTokenCookie();}}
                  >
                    <div className={classes.icon}>{<CancelPresentationIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

    </div>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  pages: PropTypes.array.isRequired,
  onSidebarOpen: PropTypes.func
};

//export default SidebarNav;
export default (props) => {
    const { t, i18n } = useTranslation();
    return (<SidebarNav lang={t} i18n={i18n} props{... props} />)
}